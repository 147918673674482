.sortable-highlight {
    background-color: $activity_highlight_color;
    border: 1px solid darken( $activity_highlight_color, 10% );
}

.select2-selection__choice.ui-state-highlight {
    background-color: $activity_highlight_color !important;
}

.select2-selection__choice {
    color: $sort_text_color !important;
}
