.modal#notifications .modal-dialog {
  position: fixed;
  margin: auto;
  width: 520px;
  height: 92%;
  -ms-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal#notifications .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal#notifications .modal-body {
  padding: 15px 15px 80px;
}

.modal#notifications.fade .modal-dialog {
  right: 0.4%;
  bottom: 4%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal#notifications .modal-dialog .modal-content {
  background-color: rgba(255, 255, 255, 0.92);
  border-radius: 0.3rem;
}

.modal#notifications.fade.in .modal-dialog {
  right: 0;
}