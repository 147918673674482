.sidebar-dark-primary {
  .nav-sidebar > .nav-item > .nav-link.active,
  .nav-treeview > .nav-item > .nav-link.active {
    &:link,
    &:visited,
    &:active {
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }
  }
}

.sidebar-dark-primary {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.environment-dev {
  .sidebar-dark-primary {
    background: rgb(65 38 38 / 84%);
  }
}

.environment-dev_validation {
  .sidebar-dark-primary {
    background: #520202;
  }
}

.environment-staging {
  .sidebar-dark-primary {
    background: #ae5e00;

    .user-panel .info a,
    .nav-sidebar .nav-link,
    .nav-treeview .nav-link {
      &:link,
      &:visited,
      &:active,
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
}

.modal {
  z-index: 9999;

  &#invoice-elements-modal .modal-body {
    padding: 0;
  }
}

.popover {
  z-index: 10000;
  pointer-events: none;
}

.overview {
  .add-new {
    margin-bottom: 1rem;
  }
}

.hide {
  display: none;
}

.hide-branchunit, .hide-default, .hide-headquarter, .hide-domicile {
  display: none;
}

#create {
  .form-group {
    .row.bg-light {
      padding-top: 0.5rem;

      input {
        margin-bottom: 1rem;
      }
    }
  }
}

#duplicate_name {
  .modal-lg {
    max-width: 900px;

    #duplicates-list {
      padding: 1.25rem 0 0 0;

      li {
        cursor: pointer;
      }
    }
  }
}

.submenu {
    padding-left: 1.25rem;
}

.comment {
  background-color: $global_background_color;

  &:hover {
    .actions {
      visibility: visible;
    }
  }

  .actions {
    visibility: hidden;

    * {
      padding-left:5px;
    }

    i.sort {
      color: $sort_handle_color;
      cursor: grab;
    }
  }
}

.toggle-off-text {
    margin-left: -0.3em;
}

.toggle-on-text {
    margin-left: 0.6em;
}

.callout-danger {
    background-color: lighten( $calloutDanger, 40% );
}

#advanced_search_filters {
    margin-top: 25px;

    .btnSaveFilter {
        margin-left: 10px;
    }

    #btnClearAllFilters {
        margin-left: 10px;
    }

    #resetFilter {
        margin-left: 10px;
    }
}

.dataTables_wrapper {
  padding: 0;
  font-size: 0.9rem;
  margin-top: 55px;

  .dt-buttons {
    float:right;
  }

  .dataTables_filter {
    float: left;
  }
}

.content table th span {
  float: left;
}

.single-bar-chart {
  i {
    float: left;
    font-style: normal;
    font-size: 10px;
    height: 17px;
    line-height: 20px;
    padding: 0 8px 0 8px;
    text-align: center;
  }

  b {
    float: left;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    height: 17px;
    position: relative;

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      background: black;

      &:hover {
        background: gray;
      }

      &.work-completed {
        background: green;
      }

      &.overwork {
        background: $calloutDanger;

        &:hover {
          background: $calloutDanger;
        }
      }
    }
  }
}

.edit-timeregistration-button,
.add-new-invoice-element,
.add-new-element,
.add-project-correction,
.add-expense-correction,
.edit-expense-correction {
  cursor: pointer;
}

#invoicing {
  .dataTables_wrapper {
    margin-top: 0;
  }
}

#creditNoteList_wrapper {
  margin-top: 0;
}

#historyList_wrapper {
  margin-top: 0;
}

#price-correction-modal {
  .btn-default.active {
    background-color: #17a2b8;
    color: white;
  }
}

//
// Fix Select2entity (readonly, theming, ...)
//

// Fixes the width of the select2 input field; otherwise it is too small and the placeholder text was cut off
.select2-container li:only-child,
.select2-container input:placeholder-shown {
  width: 100% !important;
}

.disabled-select2-entity {
  background-color: #d5d5d5;
  opacity: 0.5;
  border-radius: 3px;
  cursor: not-allowed;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;
}

select[readonly].select2-hidden-accessible + .select2-container .select2-selection {
  background: #eee;
  box-shadow: none;
}

select[readonly].select2-hidden-accessible + .select2-container .select2-selection__arrow,
select[readonly].select2-hidden-accessible + .select2-container .select2-selection__clear {
  display: none;
}

// Using Bootstrap4 theme conflicts with AdminLTE theme
.select2-container .select2-selection--single .select2-selection__rendered {
  margin-top: 0px;
}

//
// End fix Select2Entity
//

.link-color {
  color: black;
}

#filter_filters {
  > button, > a {
    float: left;
  }

  > div.select2entity-form-group {
    float: left;
    width: 30%;
    margin-left: 10px;
  }
}

#contactList_wrapper,
#projectsList_wrapper,
#advanced_search_filters,
#invoiceList_wrapper,
#timeRegistrationList_wrapper,
#correspondencesList_wrapper,
#reportTable_wrapper{
  float: left;
  width: 100%;
  margin-top: 0.5rem;
}

#correspondence-modal {
  z-index: 2050;
}
.select2-close-mask{
  z-index: 2099;
}
.select2-dropdown{
  z-index: 3051;
}

@media only screen and (max-width: 1745px) {
  #correspondence-modal {
    padding-left: 15px;

    .modal-dialog {
      width: calc(100% - 250px);
      float: right;
    }
  }
}
@media only screen and (max-width: 991px) {
  #correspondence-modal {
    .modal-dialog {
      width: 100%;
      float: none;
    }
  }
}

.parameter_wrapper fieldset.form-group {
  margin-bottom: 0;
}

table.dataTable.table-sm > thead > tr > th{
  padding-right: 30px;
}

.select2-search__field {
  width: 100% !important;
}

.topbar-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.nav-item > a.btn, .nav-item > button:not(#show-notification-modal) {
  opacity: 0.8;
}

#contactList_wrapper .table thead th,
#projectsList_wrapper .table thead th,
#invoiceList_wrapper .table thead th,
#timeRegistrationList_wrapper .table thead th,
#correspondencesList_wrapper .table thead th {
  border-bottom: 2px solid black !important;
}

.btn-red {
  color: #fff;
  background-color: #fd4757;
  border-color: #fd4757;
}

.badge-red {
  color: #fff;
  background-color: #fd4757;
}
