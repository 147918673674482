.contact_details-list_item, .list_item {
    background-color: $global_background_color;

    &:hover {
        background-color: darken($global_background_color, 5%);

        .actions {
            visibility: visible;
        }
    }

    .actions {
        visibility: hidden;

        * {
            padding-left:5px;
        }

        i.sort {
            color: $sort_handle_color;
            cursor: grab;
        }
    }

    &.file:not(:last-child) {
        > div {
            &:nth-child(2) {
                margin-bottom: 30px;
                border-bottom: 1px solid darken($global_background_color, 15%);
                padding-bottom: 15px;
            }
        }
    }
}

.contactDetailsData, .detailsData {
    .row.details {
        .label {
            font-weight:bold;
        }
    }

    h3 {
        font-weight:bold;
        font-size:1.4em;
        padding-top: 25px;
        margin-bottom: 10px;
        border-bottom: 1px solid darken($global_background_color, 15%);
    }

    .divider {
        padding: 0;
        margin: 0;
        border-right: 1px solid darken($global_background_color, 15%);
    }
}
