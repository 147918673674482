.dataTables_scrollBody thead {visibility: hidden;}
div.dataTables_scrollBody thead th,
div.dataTables_scrollBody thead td {
    line-height: 0;
    opacity: 0.0;
    width: 0px;
    height: 0px;
}

div.dataTables_scrollFoot {
    overflow: visible !important;
}

.buttons-columnVisibility {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.dt-button-collection.dropdown-menu {
    overflow-y: scroll;
    max-height: 50vh;
}
