$fa-font-path: "~font-awesome/fonts";

@import '~font-awesome/scss/font-awesome';
@import '~bootstrap/scss/bootstrap';
@import '~typeahead.js-bootstrap4-css/typeaheadjs.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
@import '~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css';
@import '~datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables/dataTables.bootstrap4.css';
@import '~admin-lte/dist/css/adminlte.min.css';
@import 'config';
@import 'admin-lte';
@import "datatables";
@import 'layout';
@import 'forms';
@import 'autosuggest';
@import 'detail_pages';
@import 'sortable';
@import 'file_upload';
@import 'recurrence';
@import 'correspondences';
@import 'edit_in_place';
@import "notifications";
@import 'tinymce';
@import 'codemirror';
@import '~select2/dist/css/select2.min.css';
@import '~select2-theme-bootstrap4/dist/select2-bootstrap.min.css';
@import '_recurrenceinput/jquery.recurrenceinput.css';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min.css';
@import '~codemirror/lib/codemirror.css';
@import "~tributejs/tribute.css";
