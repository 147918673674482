label, legend {
    &.required::after {
        color: $asterixColor;
        content: ' *';
        display:inline;
    }

    &.required.optional::after {
        content: ''
    }
}

.input-group {
    flex-wrap: wrap;

    .invalid-feedback {
        width: 100%;
    }
}

legend {
    font-weight: 700;
}

.select2entity-indent {
    padding-left: 10px;
}

#time-registration-modal,
#invoice-elements-modal,
#credit-note-elements-modal,
#elements-modal,
#change-expense-modal {
    .modal-lg {
        max-width: 90%;
    }
}

#correspondence-modal,
#edit-document-content-modal{
    .modal-lg {
        max-width: 90%;
    }

    overflow-y: auto;
}

#modal-bulk-request-show-failures,
#modal-bulk-dispatch-show-failures{
    .modal-lg {
        max-width: 1400px;
    }

    overflow-y: auto;
}

#select-file-for-invoice-modal,
#select-file-for-project-modal,
#preview-document-content-modal{
    .modal-lg {
        max-width: 1200px;
    }

    overflow-y: auto;
}

#change-project-managers-modal {
    .modal-lg {
        max-width: 1200px;
    }
}

.btn-internal-note {
    padding: 0;
}
