.nav-item {
    &.has-treeview {
        width: 100%;

        .nav-treeview {
            padding-left: 15px;
        }
    }
}
.content-wrapper > .content {
    padding: 0 1rem;
}

.user-panel {
    border-bottom: none !important;
}